export const labelColorDef = {
    yellow: "#FFDF4A",
    orange: "#FFA734"
}

export const ProductLabel = ({ className, fill }: { fill?: keyof typeof labelColorDef, className?: string }) => {
    const labelColor = labelColorDef[fill ?? "yellow"];
        
    return (
        <svg className={className} width="81" height="82" viewBox="0 0 81 82" fill={labelColor} xmlns="http://www.w3.org/2000/svg">
            <path d="M81 82L0 0H81V82Z" fill={labelColor}/>
        </svg>
    );
};
