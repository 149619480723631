import { ProductModel, ProductVariant } from '~/models';
import { Card, CardContent, CustomMoney, Typography } from '../ui';
import { Link } from '@remix-run/react';
import { AddToCartButton, ProductCounter, ProductLabelProps, ProductTopLabel } from '.';
import { Image } from '../ui';
import {
  CartLineInput,
  CurrencyCode,
} from '@shopify/hydrogen/storefront-api-types';
import placeholder from '~/assets/images/placeholder.png';
import FeaturedProductVector from '~/assets/icons/FeaturedProductVector.svg'
import { ProductLabel, labelColorDef } from '~/assets/icons/ProductLabel'
import { cn } from '~/lib/utils';
import { useState } from 'react';

interface Props {
  product: ProductModel;
  label?: ProductLabelProps;
  className?: string;
  imageClassName?: string;
  addButtonClassName?: string;
  addItemButtonClassName?: string;
  loading?: HTMLImageElement['loading'];
  quickAdd?: boolean;
  isFavorite?: boolean;
  isNew?: boolean;
  rate?: number;
  discount?: number;
  autoWidth?: boolean;
  orientation?: 'landscape' | 'portrait';
  reloadDocument?: boolean;
  isFeatured?: boolean;
}

export const ProductItem = ({
  product,
  autoWidth,
  orientation = 'portrait',
  className,
  imageClassName,
  addButtonClassName,
  addItemButtonClassName,
  reloadDocument = false,
  isFeatured = false,
  label
}: Props) => {
  const { variants } = product;
  const selectedVariant = variants?.edges && variants?.edges[0].node;
  const image = selectedVariant?.image;
  const price = selectedVariant?.priceV2;
  // TODO: Fix this constant resolve from API or Shopify
  const priceDiscount: number = 0;
  const onCart = 1;
  return (
    <Card className={cn(`border-none bg-silver/20 p-0 overflow-hidden hover:shadow-lg hover:shadow-gray-300 duration-300 hover:scale-105 hover:z-10 ${!autoWidth ? 'w-68 sm:w-60' : ''} ${orientation === 'portrait' && 'sm:max-w-md'}`, className)}>
      <CardContent className={`flex ${orientation === 'landscape' ? 'flex-row-reverse' : 'flex-col'} h-full p-0`}>
        <Link
          to={`/products/${product.handle}`}
          reloadDocument={reloadDocument}
          prefetch="intent"
          className={orientation === 'landscape' ? 'w-6/12' : ''}
        >
          <div className={cn(`relative ${orientation === 'landscape' ? 'h-full min-w-full' : 'h-44 min-w-full'}`, imageClassName)}>
            <Image
              className={cn("object-cover !h-full w-full", (isFeatured || label) ? "rounded-tr-xl" : "")}
              src={image?.url || placeholder}
              alt={image?.altText || `Picture of ${product.title}`}
            />
            {
              (isFeatured) ? <Image
                className="absolute top-0 right-0"
                src={FeaturedProductVector}
                alt="Featured icon"
              /> : undefined
            }
            {
              (label !== undefined && !isFeatured) ? <ProductTopLabel label={label} /> : undefined
            }
          </div>
        </Link>
        <section
          className={`p-0 flex-grow ${orientation === 'landscape' ? 'w-6/12 block my-auto px-4' : ''}`}
        >
          <Link to={`/products/${product.handle}`} prefetch="intent">
            <Typography
              variant="body1"
              className={`
              ${orientation === 'landscape' ? 'max-w-[200px] sm:max-w-none' : 'px-4'} 
              md:text-ellipsis md:overflow-hidden md:line-clamp-2 pt-4 text-left font-extrabold md:mb-2 mb-1 md:h-16`}
            >
              {product.title}
            </Typography>
            <Typography
              variant="italic"
              className={`${orientation === 'landscape' ? 'max-w-[200px] sm:max-w-none' : 'pl-1 px-4' } 
              md:text-ellipsis md:overflow-hidden text-left md:line-clamp-2 md:mb-2 md:h-10 font-bold`}
            >
              {product.vendor}
            </Typography>
            <Typography
              variant="body2"
              className={`${orientation === 'landscape' ? 'max-w-[200px] sm:max-w-none' : 'px-4' } 
              text-ellipsis overflow-hidden text-left line-clamp-3 h-16 md:pt-0 pt-1`}
            >
              {product.description}
            </Typography>
            <div
              className={`${orientation === 'landscape' ? 'mt-8' : 'ml-4 mt-4'
                } flex gap-2`}
            >
              {!!priceDiscount && (
                <Typography
                  variant="headline6Lighter"
                  className="text-xl line-through"
                >
                  ${priceDiscount.toString()}
                </Typography>
              )}
              {price && (
                <CustomMoney
                  CurrencySymbolWithTrailingZeros
                  showCurrencyCode
                  className='font-montserrat font-bold text-[16px] leading-[24px]'
                  data={{
                    amount: (price.amount || 0.0).toString(),
                    currencyCode: price.currencyCode as CurrencyCode,
                  }}
                />
              )}
            </div>
          </Link>
          {selectedVariant && (
            <ProductOnCart
              onCart={onCart}
              selectedVariant={selectedVariant}
              orientationLandscape={orientation === 'landscape'}
              addButtonClassName={addButtonClassName}
              addItemButtonClassName={addItemButtonClassName}
              productDescription={product.description ?? ""}
              productName={product.title ?? ""}
              productDetails={product}
            />
          )}
        </section>
      </CardContent>
    </Card>
  );
};

const ProductOnCart = ({
  onCart,
  selectedVariant,
  orientationLandscape,
  addButtonClassName,
  addItemButtonClassName,
  productDescription,
  productName,
  productDetails
}: {
  onCart: number;
  selectedVariant: ProductVariant;
  orientationLandscape?: boolean;
  addButtonClassName?: string;
  addItemButtonClassName?: string;
  productDescription?: string;
  productName: string;
  productDetails: ProductModel
}) => {
  const [addRemoveItem, setAddRemoveItem] = useState(onCart);

  const addItemHandler = (maxQuantity: number) => {
    if (addRemoveItem + 1 > maxQuantity) 
    {
      return;
    }
    setAddRemoveItem(addRemoveItem + 1);
  };
  const removeItemHandler = () => {
    if (addRemoveItem - 1 <= 0) 
    {
      return;
    }
    setAddRemoveItem(addRemoveItem - 1);
  };
  const maxQuantity = selectedVariant.quantityAvailable ?? 1;
  return (
      <div
        className={`${orientationLandscape ? 'pt-8' : 'px-4 mt-6'} 
        flex flex-wrap justify-between items-center w-full pb-4 gap-y-4 gap-x-2`}
      >
        <AddToCartButton
          disabled={!selectedVariant || !selectedVariant.availableForSale}
          onClick={() => setAddRemoveItem(onCart)}
          productDetails={productDetails}
          lines={
            selectedVariant
              ? [
                  {
                    merchandiseId: selectedVariant.id,
                    quantity: addRemoveItem,
                    attributes: [ 
                      { key: "DESCRIPTION", value: productDescription },
                      { key: "MAXQUANTITY", value: maxQuantity.toString()},
                      { key: "PRODUCTNAME", value: productName},
                    ],
                  } as CartLineInput,
                ]
              : []
          }
          width="auto"
          className={cn(`${orientationLandscape ? 'px-5 sm:px-8' : 'px-8'} py-1 h-10 w-[53px] font-bold`, addButtonClassName)}
        >
          <Typography variant="body2" className='whitespace-nowrap font-bold'>
            {!selectedVariant || !selectedVariant.availableForSale ? 'Sold out' : 'Add'}
          </Typography>
        </AddToCartButton>
        {
          selectedVariant.availableForSale && !!onCart && onCart > 0 &&
          <ProductCounter value={addRemoveItem} className={cn(`${orientationLandscape ? '2xl:w-24 xl:w-20 w-24' : '2xl:w-24 xl:w-20 w-24'} h-8 sm:h-8`, addItemButtonClassName)} iconSize={12} onAddItem={() => { addItemHandler(selectedVariant.quantityAvailable ?? 1); }} onRemoveItem={removeItemHandler} maxQuantity={maxQuantity} />
        }
      </div>
    );
}
