import { ProductLabel, labelColorDef } from "~/assets/icons";
import { Typography, TypographyVariants } from "../ui";
import { VariantProps } from "class-variance-authority";
import { cn } from "~/lib/utils";

export interface ProductLabelProps {
  label: JSX.Element,
  color: keyof typeof labelColorDef
}

const ProductTopLabel = ({ label }: { label: ProductLabelProps }) => {
  return (
    <div className='absolute top-0 -right-[0.5px] h-[82px]'>
      <ProductLabel
        fill={label.color}
      />
      {label.label}
    </div>
  );
}

interface ProductTopLabelTypographyProps {
  content: string,
  className?: string,
  variant?: VariantProps<typeof TypographyVariants>['variant'];
}

const ProductTopLabelTypography = ({ content, variant, className }: ProductTopLabelTypographyProps) => (
  <Typography variant={variant ?? "headline5"} className={cn('absolute top-6 left-4 w-[82px] text-center rotate-45 !leading-[0px] m-0', className)}>
    {content}
  </Typography>
);

export { ProductTopLabel, ProductTopLabelTypography }