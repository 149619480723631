import { useFetcher } from "@remix-run/react";
import { CartForm } from "@shopify/hydrogen";
import { CartLineInput } from "@shopify/hydrogen/storefront-api-types";
import { Button } from "../ui";
import { useEffect } from "react";
import { useToast } from "~/hooks";
import { ProductModel } from "~/models";
import { buildProductDataForTracking , getStringLastPart } from '../../lib/utils';

declare global {
  interface Window { dataLayer: any[]; }
}

interface Props {
  analytics?: unknown;
  children: React.ReactNode;
  disabled?: boolean;
  lines: CartLineInput[];
  onClick?: () => void;
  className?: string;
  width?: 'auto' | 'full';
  productDetails: ProductModel
}

export const AddToCartButton = ({
  analytics,
  children,
  disabled,
  productDetails,
  lines,
  onClick,
  className,
  width,
}: Props) => {
  const fetcher = useFetcher();

  const { toast } = useToast()
  /**
   * TODO: This is a hack to make the button work
   * We need to refactor the success logic to consider if the fetcher is idle and the errors are empty
   */
  const success = fetcher.state === "idle" && fetcher.data !== undefined && fetcher.data.cart !== undefined; // && fetcher.data?.errors.length === 0;
  const hasAnError = fetcher.state === "idle" && fetcher.data !== undefined && fetcher.data.errors.length > 0;

  useEffect(() => {
    if (hasAnError) {
      const [line] = lines;
      const productName = line.attributes?.find((attr) => attr.key === "PRODUCTNAME")?.value;
      const maxQuantity = line.attributes?.find((attr) => attr.key === "MAXQUANTITY")?.value;

      toast({
        title: "Something went wrong.",
        description: `We couldn't add ${productName} to your cart. The maximum quantity available is ${maxQuantity}.`,
      })
    }
  }, [hasAnError]);

  useEffect(() => {
    if (success) {
      onClick && onClick();
      type lineObject = {
        [key: string]: {[key: string]: any};
      };
      const linesByVariantId:lineObject  = {};
      let productsData = buildProductDataForTracking([productDetails], "");
      lines.forEach(product => {
        linesByVariantId[getStringLastPart(product.merchandiseId)] = product;
      });
      productsData = productsData.map(product => {
        const productFullId =  product.item_id ? product.item_id : "";
        product.quantity = linesByVariantId[getStringLastPart(productFullId, '_')].quantity;
        return product;
      });
      const cartValue =  productDetails.variants && productDetails.variants?.edges ? productDetails.variants?.edges[0].node?.priceV2?.amount : 0;
      const cartValueAsFloat = cartValue ? parseFloat(String(cartValue)) : 0;
      let dataLayer = window.dataLayer || [];
      dataLayer.push({ecommerce :null});
      dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          currency: 'USD',
          value: cartValueAsFloat,
          items: productsData
        },
      });
    }
  }, [success]);


  const onInternalClick = () => {
    const formData = new FormData();
    const payload = {
      inputs: { lines },
      action: CartForm.ACTIONS.LinesAdd,
    };
    formData.append('analytics', JSON.stringify(analytics));
    formData.append('cartFormInput', JSON.stringify(payload));
    fetcher.submit(formData, { method: 'post', action: '/cart' });
  }
  return (
    <Button
      variant='cool'
      type="submit"
      onClick={onInternalClick}
      disabled={disabled || fetcher.state !== 'idle'}
      className={className}
      width={width}
    >
      {children}
    </Button>
  )
}